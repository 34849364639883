import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { PapiGallection } from '@wix/da-papi-types';
import { ALL_FOLDER_ID, SCRAPS_FOLDER_ID } from '@wix/da-url';
import Flex from '@wix/da-ds/pkg/Flex';
import CollapsibleBlock from '@wix/da-shared-react/pkg/CollapsibleBlock';
import Button from '@wix/da-shared-react/pkg/Button';
import SubfolderCard from '../../SubfolderCard';
import StickyFolderBar from '../../FolderBar/StickyFolderBar';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { normalizePremiumFolderData } from '@wix/da-shared-react/pkg/utils/normalizePremiumFolderData';
import { isCurrentUserProfileOwner } from '../../../../../selectors/users';

import s from './FolderDescription.scss';

export interface Props {
  folder: PapiGallection;
  className?: string;
}

const COLLAPSE_WHEN_THIS_TALL = 78;

const getDescriptionText = (folder: PapiGallection) => {
  if ([ALL_FOLDER_ID, SCRAPS_FOLDER_ID].includes(folder.folderId)) {
    // pseudo-folders
    return '';
  }

  // Text without line breaks and html tags
  return (folder.description || '').replace(/<\/?[^>]+>/g, '');
};

export const FolderDescription: React.FC<Props> = ({ folder, className }) => {
  const isMobile = useContext(MobileContext);
  const isOwner = useSelector(isCurrentUserProfileOwner);

  const { isPremiumLocked } = normalizePremiumFolderData(
    folder.premiumFolderData
  );
  const { t } = useTranslation();

  if (!folder) {
    return null;
  }

  const description = getDescriptionText(folder);

  const subfolders = folder.subfolders ?? [];

  if (isMobile && !subfolders.length && !description) {
    return null;
  }

  const renderDescription = () => {
    if (!description) {
      return null;
    }

    return (
      <CollapsibleBlock
        maxHeight={COLLAPSE_WHEN_THIS_TALL}
        className={s['collapsible-block']}
        showFade
        fadeClassName={s['collapsible-fade']}
        renderToggleButton={({ collapsed, onClick }) => (
          <Button
            view="b3"
            className={s['collapsible-button']}
            onClick={onClick as any}
          >
            {t(
              collapsed
                ? 'pages.gallections.folderDescription.readMore'
                : 'common.showLess'
            )}
          </Button>
        )}
      >
        <p className={s['description']}>{description}</p>
      </CollapsibleBlock>
    );
  };

  const subfolderTransKeys = {
    gallery: 'pages.gallections.gallery.subfolders',
    collection: 'pages.gallections.collection.subfolders',
  };

  const renderSubfolders = () => {
    if (!subfolders.length) {
      return null;
    }
    // subfolder count should only be shown if there's description. Don't look at me.
    const showSubfolderCount = !!description;
    return (
      <div className={s['subfolders-section']}>
        <div className={s['subfolders-header']}>
          {t(subfolderTransKeys[folder.type])}{' '}
          {showSubfolderCount && (
            <span className={s['subfolders-count']}>{subfolders.length}</span>
          )}
        </div>
        <Flex wrap="wrap">
          {subfolders.map(
            subFolder =>
              (!!subFolder.size || isOwner) && (
                <SubfolderCard
                  key={subFolder.folderId}
                  subfolder={subFolder}
                  className={s['subfolder']}
                />
              )
          )}
        </Flex>
      </div>
    );
  };

  return (
    <div className={classnames(s['root'], className)}>
      <Flex direction="column">
        {!isMobile && <StickyFolderBar currentFolder={folder} />}
        {!isPremiumLocked && renderDescription()}
        {renderSubfolders()}
      </Flex>
    </div>
  );
};
FolderDescription.displayName = 'FolderDescription';

export default FolderDescription;
