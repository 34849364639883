import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  getTheme,
  getIsMobile,
  getPromos,
  getThemeSkin,
} from '@wix/da-shared-react/pkg/publicSession/selectors';
import {
  hasFirstTimeFlag,
  FirstTimeFlag,
} from '@wix/da-shared-react/pkg/redux/firstTimeFlags';
import { getEnabledFlagsList } from '@wix/da-react-context/pkg/flags/redux/selectors';
import { getIsFeedbackPageOpen } from '@wix/da-shared-react/pkg/SiteHeader/redux/selectors';
import { AppState } from '../../../types/store';
import { appLoaded, pathChange } from '../../actions/app';
import {
  isSectionLoading,
  getActiveSection,
  getActiveSectionAppUrl,
} from '../../selectors/sections';
import { getCoverDeviation } from '../../selectors/deviations';
import {
  isCurrentUserProfileOwner,
  getProfileOwnerUser,
  getProfileGruser,
} from '../../selectors/users';
import { getCanCustomizePage } from '../../selectors/privs';
import { getIsRepositioningProfileHero } from '../../selectors/profileHeroEditor';

import App, { Props } from './App';
import {
  getProfileSkin,
  isSkinSelectorShown,
} from '../../selectors/profileSkins';
import { getIsDuperbrowseActive } from '@wix/da-shared-react/pkg/Duperbrowse/redux/selectors';
import { hasBadgesInstalled } from '../../selectors/aboutMe';
import { getPageType } from '../../selectors/pageType';
import { isPWA } from '@wix/da-shared-react/pkg/utils/isPWA';
import { getEnv } from '@wix/da-react-app/pkg/client/Environment/redux/selectors';

type StateProps = Pick<
  Props,
  | 'activeSection'
  | 'environment'
  | 'profileOwner'
  | 'gruser'
  | 'theme'
  | 'themeSkin'
  | 'isMobile'
  | 'isOwner'
  | 'isSectionLoading'
  | 'showProfileHeroAid'
  | 'appUrl'
  | 'flags'
  | 'isFeedbackPageOpen'
  | 'PWAInstallBannerEligible'
  | 'profileSkin'
  | 'isAuthorizedToCustomize'
  | 'isSkinSelectorShown'
  | 'isDuperbrowseActive'
  | 'hasBadgesInstalled'
>;
type DispatchProps = Pick<Props, 'onAppRendered' | 'onPathChange'>;
type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => {
  const coverDeviation = getCoverDeviation(state);
  const profileOwnerUser = getProfileOwnerUser(state);
  const gruser = getProfileGruser(state);
  const isOwner = isCurrentUserProfileOwner(state);
  const isAuthorizedToCustomize = getCanCustomizePage(state);
  const isHeroEditingActive = getIsRepositioningProfileHero(state);
  const flags = getEnabledFlagsList(state);
  const promos = getPromos(state);
  return {
    activeSection: getActiveSection(state),
    isMobile: getIsMobile(state),
    isOwner,
    isAuthorizedToCustomize,
    profileOwner: profileOwnerUser,
    gruser,
    isSectionLoading: isSectionLoading(state),
    theme: getTheme(state),
    themeSkin: getThemeSkin(state),
    environment: getEnv(state).type,
    showProfileHeroAid:
      isOwner &&
      !coverDeviation &&
      hasFirstTimeFlag(state, FirstTimeFlag.ProfileHeroAidNotClosed) &&
      !isHeroEditingActive,
    isHeroEditingActive,
    appUrl: getActiveSectionAppUrl(state),
    pageType: getPageType(state),
    flags,
    promos,
    isFeedbackPageOpen: getIsFeedbackPageOpen(state),
    PWAInstallBannerEligible:
      !isPWA() && getIsMobile(state) && flags.includes('pwa_banners'), // feature protected by "pwa_banners"
    profileSkin: getProfileSkin(state),
    isSkinSelectorShown: isSkinSelectorShown(state),
    isDuperbrowseActive: getIsDuperbrowseActive(state as any),
    hasBadgesInstalled: hasBadgesInstalled(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  OwnProps
> = dispatch => ({
  onAppRendered: () => dispatch(appLoaded()),
  onPathChange: (location: object) => dispatch(pathChange(location)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
